import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'blueimp-canvas-to-blob';
import 'core-js/es/array';
import 'core-js/es/global-this';
import 'core-js/es/object/assign';
import 'core-js/es/object/entries';
import 'core-js/es/object/values';
import 'core-js/es/promise';
import 'core-js/es/string';

// Microsofts Edge browser has NodeList without Iterable interface, so if it's missing, we attach it here
if (!NodeList.prototype.forEach && Array.prototype.forEach) {
  // @ts-ignore those two forEaches are not exactly identical, but work for our use case
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// Hack, need to look for another solution
// @ts-ignore window.matchMedia not in types
window.matchMedia = window.matchMedia ||
  function () {
    return {
      matches: false,
      addListener() {},
      removeListener() {},
    };
  };
